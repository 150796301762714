import { Box, Heading } from "@chakra-ui/react";
import { Product } from "@prisma/client";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { useMemo } from "react";
import { ProductCard } from "../src/components/product/ProductCard";
import ProductGrid from "../src/components/product/ProductGrid";
import { DB } from "../src/data";
import { getMessages } from "../src/util/messages";

export const getServerSideProps: GetServerSideProps<{
  products: Product[];
}> = async ({ locale }) => {
  const products = await DB.getProducts();

  //const products = await getLocalCoffeeList().catch(() => []);
  const messages = await getMessages(HomeScreen.Messages, locale);

  return {
    props: {
      messages,
      products,
    },
  };
};

export type HomeScreenProps = {} & InferGetServerSidePropsType<
  typeof getServerSideProps
>;

function HomeScreen({ products }: HomeScreenProps) {
  const productCards = useMemo(() => {
    const unique: { [uid: string]: Product } = {};
    (products || []).forEach((c) => {
      if (!(c.id in unique)) {
        unique[c.id] = c;
      }
    });
    return Object.values(unique)
      .sort((p1, p2) => p2.reviewCount - p1.reviewCount)
      .map((c) => <ProductCard key={c.id} product={c} />);
  }, [products]);

  return (
    <Box mx={{ base: 4, md: "unset" }}>
      <Heading mt={3} color="primary.600">
        Welcome to Hello Green Market
      </Heading>
      <Box mt={6} />
      <ProductGrid>{productCards}</ProductGrid>
    </Box>
  );
}

HomeScreen.Messages = [
  "Home",
  ...ProductGrid.Messages,
  ...ProductCard.Messages,
];

export default HomeScreen;
